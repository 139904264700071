import React, { useContext, useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import constant from "../../Components/Services/constant";
import numeral from "numeral";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import Modal from "react-bootstrap/Modal";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper/modules";
import "swiper/css/thumbs";
import "swiper/css/free-mode";
import LoginModal from "./login_modal";
import StarRating from "../Elements/starrating";
import multiCurrency from "../../Components/Elements/multi_currrency";
import { showToast } from "../../Components/Elements/toastUtils";
import sessionCartData from "../../Components/Elements/cart_session_data";
import DataContext from "../Elements/eventContext";
import Loader from "react-js-loader";
import { addToCart } from "../Elements/add_to_cart";
function QuickViewModal() {
  const didMountRef = useRef(true);
  const contextValues = useContext(DataContext)
  const Navigate = useNavigate();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [rowProductsData, setProductsData] = useState({});
  const [variationDataa, setVariationData] = useState([]);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [arySelectedData, setArySelectedData] = useState([]);
  const [selvararray, setSelvararray] = useState([]);
  const [galleryItems, setGalleryItems] = useState([]);

  const [setSession, SetSession] = useState("");
  const [quantity, setQuantity] = useState(1);
  let mrpValue = 0;
  let sellingPriceValue = 0;
  let discount = 0;

  useEffect(() => {
    if (didMountRef.current) {
      SetSession(localStorage.getItem("USER_TOKEN"));
      if (contextValues.productData && Object.keys(contextValues.productData).length > 0) {
        getProductDetails()
      }
    }
    didMountRef.current = false;
  }, []);

  const getProductDetails = () => {
    const dataString = {
      slug: contextValues.productData.product_slug,
    };
    ApiService.postData("product-details", dataString).then((res) => {
      if (res.status === "success") {
        recentlyProducts(res.rowProductData)
        setSelvararray(res.selvararray);
        setProductsData(res.rowProductData);
        setVariationData(res.variationData);
        setTimeout(() => {
          mrpValue = parseFloat(res.rowProductData.product_price);
          sellingPriceValue = parseFloat(
            res.rowProductData.product_selling_price
          );
          if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
            discount = ((mrpValue - sellingPriceValue) / mrpValue) * 100;
            setDiscountPercentage(discount.toFixed(2));
          }
          let parentcounter = 0;
          let childcounter = 0;
          res.variationData.map((parent) => {
            if (parent.attributes && parent.attributes.attribute_type == 3) {
              parent.attr_terms.map((child) => {
                parentcounter++;
                if (parentcounter == 1) {
                  arySelectedData.push(child.terms_name);
                }
              });
              parentcounter = 0;
            } else if (parent.attributes && parent.attributes.attribute_type == 2) {
              parent.attr_terms.map((child) => {
                childcounter++;
                if (childcounter == 1) {
                  arySelectedData.push(child.terms_name);
                }
              });
              childcounter = 0;
            } else if (parent.attributes && parent.attributes.attribute_type == 1) {
              parent.attr_terms.map((child) => {
                childcounter++;
                if (childcounter == 1) {
                  arySelectedData.push(child.terms_name);
                }
              });
              childcounter = 0;
            }
            setSpinnerLoading(false);
          });
          const galleryItems = [
            {
              original: res.rowProductData.product_image != null ? res.rowProductData.product_image : constant.DEFAULT_IMAGE,
              thumbnail: res.rowProductData.product_image != null ? res.rowProductData.product_image : constant.DEFAULT_IMAGE,
            },
          ];

          res.rowProductData.gallery.map((value) => {
            galleryItems.push({
              original: value.gallery_image != null ? value.gallery_image : constant.DEFAULT_IMAGE,
              thumbnail: value.gallery_image != null ? value.gallery_image : constant.DEFAULT_IMAGE,
            });
          });
          setGalleryItems(galleryItems)
          setSpinnerLoading(false);
        }, 1000);
      } else {
        setSpinnerLoading(false);
      }
    });
  };

  const variationSelect = (item, index) => {
    const updatedSelectedData = [...selvararray];
    updatedSelectedData[index] = item.terms_name;
    const variationNewArray = updatedSelectedData;
    const dataString = {
      variation: variationNewArray,
      product_id: rowProductsData.product_id,
    };

    ApiService.postData("variation-wise-price", dataString).then((res) => {
      setSelvararray(variationNewArray);
      rowProductsData.product_sku = res.data.pv_sku;
      rowProductsData.product_selling_price = res.data.pv_sellingprice;
      rowProductsData.product_price = res.data.pv_price;
      rowProductsData.product_stock = res.data.pv_quantity;
      rowProductsData.product_moq = res.data.pv_moq;
      rowProductsData.product_discount = res.data.pv_discount;
      if (item.variation_images) {
        rowProductsData.product_image =
          item.variation_images.pti_image != null
            ? item.variation_images.pti_image
            : constant.DEFAULT_IMAGE;
      } else {
        rowProductsData.product_image =
          rowProductsData.product_image != null
            ? rowProductsData.product_image
            : constant.DEFAULT_IMAGE;
      }
      setProductsData(rowProductsData);
      mrpValue = parseFloat(res.data.pv_price);
      sellingPriceValue = parseFloat(res.data.pv_sellingprice);
      if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
        discount = ((mrpValue - sellingPriceValue) / mrpValue) * 100;
      }
      setDiscountPercentage(discount.toFixed(2));
      setQuantity(1);
    });
  };

  const addtocartsession = (addproduct, purchaseType) => {
    const existingProductIndex = contextValues.cartSessionData.findIndex((item) => {
      return (
        item.product_id === addproduct.product_id &&
        JSON.stringify(item.product_variation) ===
        JSON.stringify(selvararray)
      );
    });
    let quantityNew = 1;
    if (existingProductIndex !== -1) {
      quantityNew = contextValues.cartSessionData[existingProductIndex].quantity + Number(quantity);
    }
    const dataString = {
      product_id: Number(addproduct.product_id),
      product_name: addproduct.product_name,
      product_slug: addproduct.product_slug,
      product_image: addproduct.product_image
        ? addproduct.product_image
        : constant.DEFAULT_IMAGE,
      product_type: Number(addproduct.product_type),
      product_price: parseFloat(addproduct.product_price),
      product_selling_price: parseFloat(addproduct.product_selling_price),
      product_discount: parseFloat(addproduct.product_discount),
      product_variation: selvararray,
      quantity: Number(quantityNew),
    };
    contextValues.setSpinnerCubLoader(addproduct.product_id + purchaseType)
    ApiService.postData("addtocartsession", dataString).then((res) => {
      if (res.data.status === "success") {
        showToast('success', res.data.message, 1000);
        localStorage.removeItem("COUPON_SESSION");
        contextValues.setCartCount(res.data.resCartData.length)
        contextValues.setCartSessionData(res.data.resCartData)
        contextValues.setToggleQuickViewModal(false)

        contextValues.setSpinnerCubLoader(0)
        if (purchaseType === 1) {
          contextValues.setToggleCheckoutModal(true)
        } else {
          contextValues.setToggleCartModal(true)
        }
      } else {
        showToast('error', res.data.message, 1000);
        contextValues.setSpinnerCubLoader(0)
      }
    });

  };
  const addtocart = async (addproduct, purchaseType) => {
    contextValues.setSpinnerCubLoader(addproduct.product_id + purchaseType)
    const productData = {
      product_id: Number(addproduct.product_id),
      product_name: addproduct.product_name,
      product_slug: addproduct.product_slug,
      product_image: addproduct.product_image
        ? addproduct.product_image
        : constant.DEFAULT_IMAGE,
      product_type: Number(addproduct.product_type),
      product_price: Number(addproduct.product_price),
      product_selling_price: Number(addproduct.product_selling_price),
      product_discount: addproduct.product_discount,
      product_variation: selvararray,
      product_category_id: addproduct.product_category_id,
    };
    const updateStatus = await addToCart(productData, quantity, contextValues);
    if (updateStatus) {
      contextValues.setSpinnerCubLoader(0)
      contextValues.setToggleQuickViewModal(false)
      if (purchaseType === 1) {
        contextValues.setToggleCheckoutModal(true)
      } else {
        contextValues.setToggleCartModal(true)
      }
    } else {
      contextValues.setSpinnerCubLoader(0)
    }
  };

  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  const addtofav = (productId) => {
    const dataString = {
      product_id: productId,
    };
    setSpinnerLoading(true)
    ApiService.postData("add-to-fav", dataString).then((res) => {
      if (res.data.status === "success") {
        var element = document.getElementById("wishlisticon" + productId);
        element.classList.remove("d-icon-heart", "d-icon-heart-full");
        element.classList.add(res.data.notification);
        getProductDetails()
        if (res.data.notification === "d-icon-heart") {
          toast.success("Removed from wishlist");
          setSpinnerLoading(false)
        } else {
          toast.success("Added to Wishlist");
          setSpinnerLoading(false)
        }
      }
    });
  };
  const recentlyProducts = (value) => {
    let recentlyProductsSession = localStorage.getItem("RECENTLY_VIEWED");
    recentlyProductsSession = recentlyProductsSession ? JSON.parse(recentlyProductsSession) : [];

    if (!Array.isArray(recentlyProductsSession)) {
      recentlyProductsSession = [];
    }

    const existingProductIndex = recentlyProductsSession.findIndex((item) => {
      return item.product_id === value.product_id;
    });

    if (existingProductIndex !== -1) {
      recentlyProductsSession[existingProductIndex] = value;
    } else {
      if (recentlyProductsSession.length >= 10) {
        recentlyProductsSession.shift();
      }
      recentlyProductsSession.push(value);
      localStorage.setItem('RECENTLY_VIEWED', JSON.stringify(recentlyProductsSession));
    }
  }

  const loginModal = () => {
    contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
  }

  const quickviewmodal = () => {
    contextValues.setToggleQuickViewModal(!contextValues.toggleQuickViewModal)
  }

  const getStockStatus = (rowProductsData) => {
    const {
      product_type,
      product_inventory,
      product_stock,
      product_backorder,
      product_moq
    } = rowProductsData;

    if (Number(product_type) === 0) {
      if (Number(product_inventory) === 1) {
        if (Number(product_stock) === 0) {
          return Number(product_backorder) === 0 || Number(product_backorder) === 1
            ? "Out of Stock"
            : "In Stock";
        } else {
          return "In Stock";
        }
      } else {
        console.log("product_moqproduct_moq",product_moq);
        
        return Number(product_moq) === 0 ? "Out of Stock" : "In Stock";
      }
    } else {
      return Number(product_stock) === 0 ? "Out of Stock" : "In Stock";
    }
  };
  return (
    <>
      <button onClick={(e) => quickviewmodal()} className="pop-close "><i className="d-icon-times"></i></button>
      <Modal.Body className="p-0">
        {spinnerLoading && (<SpinnerLoader />)}
        <Swiper
          className="mproduct-details-carousel"
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed
                ? thumbsSwiper
                : null,
          }}
          modules={[Autoplay, FreeMode, Navigation, Thumbs]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <img
              src={
                rowProductsData.product_image != null
                  ? rowProductsData.product_image
                  : constant.DEFAULT_IMAGE
              }
              alt={rowProductsData.product_name}

            />
          </SwiperSlide>
          {rowProductsData.gallery ? (
            rowProductsData.gallery.map((value, indexProduct) => (
              <SwiperSlide key={indexProduct}>
                <img
                  src={
                    value.gallery_image != null
                      ? value.gallery_image
                      : constant.DEFAULT_IMAGE
                  }
                  alt={rowProductsData.product_name}
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <img
                src={constant.DEFAULT_IMAGE}
                alt={rowProductsData.product_name}
              />
            </SwiperSlide>
          )}
        </Swiper>
        <div className="product-single p-3">
          <h2 className="product-name mt-3">{rowProductsData.product_name}</h2>
          <div className="product-meta">
            {rowProductsData.product_sku ? (<>SKU:<span className="product-sku">{rowProductsData.product_sku}</span></>) : null}
            {rowProductsData.product_brand_name ? (<>Brand:<span className="product-sku">{rowProductsData.product_brand_name}</span></>) : null}
          </div>
          <div className="product-price">
            <ins className="new-price">{multiCurrency(rowProductsData.product_selling_price)}</ins>
            {discountPercentage > 0 ? (
              <>
                <del className="old-price">
                  ₹{numeral(rowProductsData.product_price).format("0,0.00")}
                </del>
                <span className="off">{discountPercentage}% Off</span>
              </>
            ) : null}
          </div>
          {rowProductsData.product_rating && rowProductsData.product_rating > 0 ?
            <div className="ratings-container">
              <StarRating numberOfStars={rowProductsData.product_rating} />
              <span>( {rowProductsData.product_review} reviews )</span>
            </div> : ''}
          <div className="stock-text">
            Availability:
            <span className={getStockStatus(rowProductsData) === "Out of Stock" ? "outofstock" : "instock"}>
              {getStockStatus(rowProductsData)}
            </span>
          </div> 
          {rowProductsData.product_content ? (<div className="product-short" dangerouslySetInnerHTML={{ __html: rowProductsData.product_content, }}></div>) : null}
          {rowProductsData.product_highlight ? (
            <div className="product-highlight">
              <ul>
                {rowProductsData.product_highlight
                  .split("##")
                  .map((highlightvalue, indextag) => {
                    return <li key={indextag}>{highlightvalue}</li>;
                  })}
              </ul>
            </div>
          ) : null}
          {spinnerLoading === false && (
            <>
              {variationDataa.map((valueVariation, indexVariation) => {
                if (valueVariation.attributes && valueVariation.attributes.attribute_type === 1) {
                  return (
                    <div className="dvariation" key={indexVariation}>
                      <label>
                        {valueVariation.attributes.attribute_name}:
                      </label>
                      <div className="dvariation-list">
                        {valueVariation.attr_terms.map(
                          (
                            valueVariationAttr,
                            indexvalueVariationAttr
                          ) => {
                            const stringIncluded = selvararray.includes(
                              valueVariationAttr.terms_name
                            );
                            const className = stringIncluded
                              ? "color active"
                              : "color";
                            return (
                              <a
                                onClick={() =>
                                  variationSelect(
                                    valueVariationAttr,
                                    indexVariation
                                  )
                                }
                                className={className}
                                key={indexvalueVariationAttr}
                                data-src={constant.DEFAULT_IMAGE}
                                href="javascript:void(0)"
                                style={{
                                  backgroundColor:
                                    valueVariationAttr.terms_value,
                                  display: "block",
                                }}
                              ></a>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                } else if (
                  valueVariation.attributes && valueVariation.attributes.attribute_type === 2
                ) {
                  return (
                    <div className="dvariation" key={indexVariation}>
                      <label>
                        {valueVariation.attributes.attribute_name}:
                      </label>
                      <div className="dvariation-list">
                        {valueVariation.attr_terms.map(
                          (
                            valueVariationAttr,
                            indexvalueVariationAttr
                          ) => {
                            const stringIncluded = selvararray.includes(
                              valueVariationAttr.terms_name
                            );
                            const className = stringIncluded
                              ? "swatch active"
                              : "swatch";
                            return (
                              <a
                                onClick={() =>
                                  variationSelect(
                                    valueVariationAttr,
                                    indexVariation
                                  )
                                }
                                className={className}
                                key={indexvalueVariationAttr}
                                href="javascript:void(0)"
                                style={{
                                  backgroundImage: `url(${valueVariationAttr.variation_images !=
                                    null
                                    ? valueVariationAttr
                                      .variation_images.pti_image
                                    : constant.DEFAULT_IMAGE
                                    })`,
                                  backgroundColor: "#c8c7ce",
                                }}
                              >
                                <img
                                  src={
                                    valueVariationAttr.variation_images !=
                                      null
                                      ? valueVariationAttr
                                        .variation_images.pti_image
                                      : constant.DEFAULT_IMAGE
                                  }
                                  alt={rowProductsData.product_name}
                                  width="100"
                                  height="100"
                                />
                              </a>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                } else if (
                  valueVariation.attributes && valueVariation.attributes.attribute_type === 3
                ) {
                  return (
                    <div className="dvariation" key={indexVariation}>
                      <label>
                        {valueVariation.attributes.attribute_name}:
                      </label>

                      <div className="dvariation-list">
                        {valueVariation.attr_terms.map(
                          (
                            valueVariationAttr,
                            indexvalueVariationAttr
                          ) => {
                            const stringIncluded =
                              selvararray.includes(
                                valueVariationAttr.terms_name
                              );
                            const className = stringIncluded
                              ? "size active"
                              : "size";
                            return (
                              <a
                                onClick={() =>
                                  variationSelect(
                                    valueVariationAttr,
                                    indexVariation
                                  )
                                }
                                className={className}
                                href="javascript:void(0)"
                                key={indexvalueVariationAttr}
                              >
                                {valueVariationAttr.terms_name}
                              </a>
                            );
                          }
                        )}
                      </div>

                    </div>
                  );
                }
                return null;
              })}
            </>
          )}
          <hr className="product-divider mb-3" />
          <div className="product-button">
            <div className="pbqty">
              <button className="quantity-minus d-icon-minus" onClick={handleDecrease}></button>
              <input type="number" className="quantity" value={quantity} />
              <button className="quantity-plus d-icon-plus" onClick={handleIncrease}></button>
            </div>
            {setSession ?
              <>
                {contextValues.spinnerCubLoader == rowProductsData.product_id + 0 ? <button className="btn btn-primary me-2"><Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /></button> :
                  <>
                    <button className="btn btn-primary me-2" onClick={(e) => addtocartsession(rowProductsData, 0)}><i className="d-icon-bag"></i>Add To Cart</button>
                  </>
                }
                {contextValues.spinnerCubLoader == rowProductsData.product_id + 1 ? <button className="btn btn-primary"><Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /></button> :
                  <>
                    <button className="btn btn-primary" onClick={(e) => addtocartsession(rowProductsData, 1)}>Buy Now</button>
                  </>
                }
              </> :
              <>
                {contextValues.spinnerCubLoader == rowProductsData.product_id + 0 ? <button className="btn btn-primary me-2"><Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /></button> :
                  <>
                    <button className="btn btn-primary me-2" onClick={(e) => addtocart(rowProductsData, 0)}><i className="d-icon-bag"></i>Add To Cart</button>
                  </>
                }
                {contextValues.spinnerCubLoader == rowProductsData.product_id + 1 ? <button className="btn btn-primary"><Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /></button> :
                  <>
                    <button className="btn btn-primary" onClick={(e) => addtocart(rowProductsData, 1)}>Buy Now</button>
                  </>
                }   </>}
          </div>
          <hr className="product-divider mb-3" />
          <div className="product-footer">
            <div className="social-links mr-4">
              {contextValues.settingData.facebook_url != null ? (
                <a
                  href={contextValues.settingData.facebook_url}
                  className="social-link social-facebook fab fa-facebook-f"
                  target="new"
                ></a>
              ) : (
                ""
              )}
              {contextValues.settingData.twitter_url != null ? (
                <a
                  href={contextValues.settingData.twitter_url}
                  className="social-link social-twitter fab fa-twitter"
                  target="new"
                ></a>
              ) : (
                ""
              )}

              {contextValues.settingData.pinterest_url != null ? (
                <a
                  href={contextValues.settingData.pinterest_url}
                  className="social-link social-pinterest fab fa-pinterest-p"
                  target="new"
                >
                  {" "}
                </a>
              ) : (
                ""
              )}
            </div>
            <span className="divider d-lg-show"></span>
            <div className="product-action">
              {setSession ?
                rowProductsData.ufp_id > 0 ?
                  <a href="javascript:void(0)" className="btn-product btn-wishlist mr-6" onClick={(e) => addtofav(rowProductsData.product_id)}>
                    <i className="d-icon-heart-full" id={'wishlisticon' + rowProductsData.product_id}></i>
                    <span>Remove from wishlist</span>
                  </a> :
                  <a href="javascript:void(0)" className="btn-product btn-wishlist mr-6" onClick={(e) => addtofav(rowProductsData.product_id)}>
                    <i className="d-icon-heart" id={'wishlisticon' + rowProductsData.product_id}></i>
                    <span>Add to wishlist</span>
                  </a>
                :
                <a href="javascript:void(0)" className="btn-product btn-wishlist mr-6" onClick={loginModal}>
                  <i className="d-icon-heart"></i>
                  <span>Add to wishlist</span>
                </a>
              }
            </div>
          </div>
        </div>

      </Modal.Body>
    </>
  );
}
export default QuickViewModal;
