import React, { useEffect, useState, useRef, useContext } from "react";
import DataContext from "../Elements/eventContext";
import sessionCartData from "../Elements/cart_session_data";
import { ApiService } from "../Services/apiservices";
import Loader from "react-js-loader";
import { toast } from "react-toastify";
function CouponModal() {
  const didMountRef = useRef(true);
  const sessionData = sessionCartData();
  const cartSummary = sessionData[3];
  //console.log("cartSummary",cartSummary);
  const contextValues = useContext(DataContext);
  const [couponsList, setCouponsList] = useState([]);
  const [proccessLoader, setProccessLoader] = useState(false);

  useEffect(() => {
    if (didMountRef.current) {
      if (localStorage.getItem("USER_TOKEN")) {
        cartSessionData();
      } else{
        let cartSession = localStorage.getItem("CART_SESSION");
        cartSession = cartSession ? JSON.parse(cartSession) : [];
        contextValues.setCartSessionData(cartSession);
      }
      couponsListData()
    }
    didMountRef.current = false;
  }, []);

  const cartSessionData = () => {
    const dataString = {
      coupon_session: localStorage.getItem("COUPON_SESSION"),
    };
    ApiService.postData("cartSessionData", dataString).then((res) => {
      if (res.data.status === "success") {
        contextValues.setCartSessionData(res.data.resCartData)
        contextValues.setCartCount(res.data.resCartData.length)
        contextValues.setCartSummary(res.data.cartSummary)
      }
    });
  }

  const couponModal = () => {
    contextValues.setToggleCouponModal(!contextValues.toggleCouponModal)
  }

  const couponsListData = () => {
    contextValues.setSpinnerCubLoader(1)
    ApiService.fetchData("coupons-list").then((res) => {
      if (res.status === 'success') {
        setCouponsList(res.resCouponsData)
        contextValues.setSpinnerCubLoader(0)
      } else {
        contextValues.setSpinnerCubLoader(0)
      }
    }).catch((error) => {
      contextValues.setSpinnerCubLoader(0)
    });
  }

  const applyCoupon = (couponValues) => {
    const productData = {
      promo_code: couponValues.promo_coupon_code,
      cart_total: contextValues.cartSummary && contextValues.cartSummary.sellingTotal ? contextValues.cartSummary.sellingTotal : 0,
    };
    setProccessLoader(true)
    ApiService.postData("select-coupon", productData).then((res) => {
      if (res.data.status === 'success') {
        toast.success(res.data.notification)
        localStorage.removeItem("COUPON_SESSION");
        const couponSessionObj = {
          discount_amount: res.data.discount_amount,
          promo_id: res.data.promo_id,
          promo_code: res.data.promo_code,
        };
        localStorage.setItem("COUPON_SESSION", JSON.stringify(couponSessionObj));
        cartSessionData()
        contextValues.setCouponSession(couponSessionObj)
        setProccessLoader(false)
        setTimeout(() => {
          contextValues.setToggleCouponModal(!contextValues.toggleCouponModal)
        }, 500);
      } else {
        toast.error(res.data.notification)
        setProccessLoader(false)
      }
    }).catch((error) => {
      toast.error(error)
      setProccessLoader(false)
    });
  }

  const [promoCode, setPromoCode] = useState({ promo_code: "", });
  const onTodoChange = (e) => {
    const { name, value } = e.target;
    setPromoCode((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const applyCouponTyping = () => {
    if (promoCode.promo_code == '') {
      toast.error("Please enter Coupon Code");
      return false;
    }
    const productData = {
      promo_code: promoCode.promo_code,
      cart_total: contextValues.cartSummary && contextValues.cartSummary.sellingTotal ? contextValues.cartSummary.sellingTotal : 0,
    };
    setProccessLoader(true)
    ApiService.postData("select-coupon", productData).then((res) => {
      if (res.data.status === 'success') {
        toast.success(res.data.notification)
        localStorage.removeItem("COUPON_SESSION");
        const couponSessionObj = {
          discount_amount: res.data.discount_amount,
          promo_id: res.data.promo_id,
          promo_code: res.data.promo_code,
        };
        localStorage.setItem("COUPON_SESSION", JSON.stringify(couponSessionObj));
        cartSessionData()
        contextValues.setCouponSession(couponSessionObj)
        setProccessLoader(false)
        setTimeout(() => {
          contextValues.setToggleCouponModal(!contextValues.toggleCouponModal)
        }, 500);
      } else {
        toast.error(res.data.notification)
        setProccessLoader(false)
      }
    }).catch((error) => {
      toast.error(error)
      setProccessLoader(false)
    });
  }

  return (
    <>
      <div show={contextValues.toggleCouponModal} className="couponModal">
        <div className="couponModalHeader">
          <h6 className="mb-0">Apply Coupon</h6>
          <button type="button" className="btnClose" onClick={(e) => couponModal()}><i className="ri-close-line ri-2x"></i></button>
        </div>
        {proccessLoader && <div className="pageLoder"><Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={50} /></div>}
        {contextValues.spinnerCubLoader > 0 ? <div className="pageLoder"><Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={50} /></div> :
          <div className="couponModalContent">
            <div className="couponApply">
              <input
                type="text"
                placeholder="Enter coupon code here"
                name="promo_code"
                className="promoCodeRequired"
                value={promoCode.promo_code}
                onChange={(e) => onTodoChange(e)}
              />
              <button type="button" className="applybutton" onClick={(e) => applyCouponTyping()}>Apply</button>
            </div>
            {couponsList && couponsList.length > 0 ? (
              couponsList.map((value, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="coupon">
                      <div className="tengah p-3 d-flex w-100 justify-content-start">
                        <div>
                          <h6 className="mb-1 tx-15 fw600 line20">{value.promo_name}</h6>
                          <p className="mb-0 tx-12">{value.promo_description}</p>
                        </div>
                      </div>
                      <div className="kanan">
                        <div className="info d-flex align-items-center">
                          <a href="javascript:;" rel="noopener noreferrer" className="btn  btn-primary btn-cartrec" onClick={(e) => applyCoupon(value)}>Apply</a>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })
            ) : (
              <div className="tengah p-3 d-flex w-100 justify-content-start">No coupons available.</div>
            )}
          </div>
        }
      </div>
    </>
  );
}
export default CouponModal;
