// const API_URL =   "https://www.tamolo.in/projects/swadeshipitara/csadmin/api/"

const API_URL =   "https://swadeshipitara.com/csadmin/api/"
const BASE_URL =   "https://swadeshipitara.com/" 
const FRONT_URL =   "https://swadeshipitara.com/" 
const LOGO_IMAGE =   "/img/logo.png" 

const DEFAULT_IMAGE = "/img/defaultimage.png";
const CATEGORIES = [];
const themesetting = {}; 
export default { API_URL, CATEGORIES, themesetting, BASE_URL,FRONT_URL,DEFAULT_IMAGE,LOGO_IMAGE };
