import React, { useEffect, useState, useRef, useContext } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Components/Services/apiservices";
import numeral from "numeral"; 
import SpinnerLoader from "../Elements/spinner_loader";
import constant from "../Services/constant";
import VariationModal from "./variation_modal";
import { Swiper, SwiperSlide } from "swiper/react";
import multiCurrency from "../../Components/Elements/multi_currrency";
import { showToast } from "../../Components/Elements/toastUtils";
import DataContext from "../Elements/eventContext";
import { addToCart } from "../Elements/add_to_cart";
import { useNavigate } from "react-router-dom";
function CartModal() {
  const navigate = useNavigate();
  const contextValues = useContext(DataContext)
  const didMountRef = useRef(true);
  const [setSession, SetSession] = useState(localStorage.getItem("USER_TOKEN"));
  const [recommendedData, setRecommendedProductsData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  let { itemTotal } = 0; 
  const variationModal = (data) => {
    contextValues.setProductData(data)
    setTimeout(() => {
      contextValues.setToggleVariationModal(!contextValues.toggleVariationModal);
    }, 100)
  };
  useEffect(() => {
    if (didMountRef.current) {
      if (setSession) {
        cartSessionData();
      }
      recommendedproductslist();
    }
    didMountRef.current = false;
  }, []);
  const cartSessionData = () => {
    const dataString = {
      coupon_session: localStorage.getItem("COUPON_SESSION"),
    };
    ApiService.postData("cartSessionData", dataString).then((res) => {
      if (res.data.status === "success") {
        contextValues.setCartSessionData(res.data.resCartData)
        contextValues.setCartCount(res.data.resCartData.length)
        contextValues.setCartSummary(res.data.cartSummary)
      }
    });
  }
  const removeProduct = (productData) => {
    setSpinnerLoading(true);
    if (localStorage.getItem("USER_TOKEN")) {
      const dataString = {
        cart_id: productData.cart_id,
      };
      ApiService.postData("removecartproduct", dataString).then((res) => {
        if (res.data.status === "success") {
          contextValues.setCartSessionData(res.data.resCartData)
          contextValues.setCartCount(res.data.resCartData.length)
          setTimeout(() => {
            setSpinnerLoading(false);
          }, 500);
        } else {
          setTimeout(() => {
            setSpinnerLoading(false);
          }, 500);
        }
      });
    } else {
      let cartSession = localStorage.getItem("CART_SESSION");
      cartSession = cartSession ? JSON.parse(cartSession) : [];
      const existingProductIndex = cartSession.findIndex((item) => {
        return (
          item.product_id === productData.product_id &&
          JSON.stringify(item.product_variation) ===
          JSON.stringify(productData.product_variation)
        );
      });

      if (existingProductIndex !== -1) {
        cartSession.splice(existingProductIndex, 1);
        localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
        let cartSessionSet = localStorage.getItem("CART_SESSION");
        cartSessionSet = cartSessionSet ? JSON.parse(cartSessionSet) : [];
        contextValues.setCartSessionData(cartSessionSet)
        contextValues.setCartCount(cartSessionSet.length)
      }
      setTimeout(() => {
        setSpinnerLoading(false);
      }, 500);
    }
  };

  const recommendedproductslist = () => {
    ApiService.fetchData("recommended-products-list").then((res) => {
      if (res.status === "success") {
        setRecommendedProductsData(res.recommendedproducts);
        setSpinnerLoading(false);
      } else {
        setSpinnerLoading(false);
      }
    });
  };

  const addtocartsession = (addproduct, purchaseType) => {
    const existingProductIndex = contextValues.cartSessionData.findIndex((item) => {
      return (
        item.product_id === addproduct.product_id &&
        JSON.stringify(item.product_variation) ===
        JSON.stringify([])
      );
    });
    let quantity = 1;
    if (existingProductIndex !== -1) {
      quantity = contextValues.cartSessionData[existingProductIndex].quantity + 1;
    }
    const dataString = {
      product_id: Number(addproduct.product_id),
      product_name: addproduct.product_name,
      product_slug: addproduct.product_slug,
      product_image: addproduct.product_image
        ? addproduct.product_image
        : constant.DEFAULT_IMAGE,
      product_type: Number(addproduct.product_type),
      product_price: parseFloat(addproduct.product_price),
      product_selling_price: parseFloat(addproduct.product_selling_price),
      product_discount: parseFloat(addproduct.product_discount),
      product_variation: [],
      quantity: Number(quantity),
    };
    contextValues.setSpinnerCubLoader(addproduct.product_id)
    ApiService.postData("addtocartsession", dataString).then((res) => {
      if (res.data.status === "success") {
        showToast('success', res.data.message, 1000);
        localStorage.removeItem("COUPON_SESSION");
        contextValues.setCartSessionData(res.data.resCartData)
        contextValues.setCartCount(res.data.resCartData.length)
        contextValues.setCartSessionData(res.data.resCartData)
        contextValues.setToggleQuickViewModal(false)

        contextValues.setSpinnerCubLoader(0)
        if (purchaseType === 1) {
          contextValues.setToggleCheckoutModal(true)
        } else {
          contextValues.setToggleCartModal(true)
        }
      } else {
        showToast('error', res.data.message, 1000);
        contextValues.setSpinnerCubLoader(0)
      }
    });

  };

  const addtocart = async (addproduct, purchaseType) => {
    contextValues.setSpinnerCubLoader(addproduct.product_id)
    const productData = {
      product_id: Number(addproduct.product_id),
      product_name: addproduct.product_name,
      product_slug: addproduct.product_slug,
      product_image: addproduct.product_image
        ? addproduct.product_image
        : constant.DEFAULT_IMAGE,
      product_type: Number(addproduct.product_type),
      product_price: Number(addproduct.product_price),
      product_selling_price: Number(addproduct.product_selling_price),
      product_discount: addproduct.product_discount,
      product_variation: [],
      product_category_id: addproduct.product_category_id,
    };
    const updateStatus = await addToCart(productData, 1, contextValues);
    if (updateStatus) {
      contextValues.setSpinnerCubLoader(0)
      contextValues.setToggleQuickViewModal(false)
      if (purchaseType === 1) {
        contextValues.setToggleCheckoutModal(true)
      } else {
        contextValues.setToggleCartModal(true)
      }
    } else {
      contextValues.setSpinnerCubLoader(0)
    }
  };

  const productCarouselOptions = {
    loop: true,
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      0: {
        slidesPerView: 1.5,
      },
      600: {
        slidesPerView: 1.5,
      },
      1000: {
        slidesPerView: 1.5,
      },
    },
  };

  const cartModal = () => {
    contextValues.setToggleCartModal(!contextValues.toggleCartModal)
  }

  const checkoutModal = () => {
    //navigate('/checkout')
    contextValues.setToggleCartModal(!contextValues.toggleCartModal)
    contextValues.setToggleCheckoutModal(!contextValues.toggleCheckoutModal)
  }

  return (
    <>
      {spinnerLoading && <SpinnerLoader />}
      {recommendedData.length > 0 && contextValues.cartSessionData.length > 0 && (
        <BrowserView>
          <div className="cartbox-recom text-center d-none d-md-block">
            <h6 className="tx-14">Recommendations For You</h6>
            <div className="cartbox-recom-inner">
              {recommendedData.map((value, index) => {
                return (
                  <div className="product">
                    <figure className="product-media">
                      <a href={"/product/" + value.product_slug}>
                        <img
                          src={
                            value.product_image != null
                              ? value.product_image
                              : constant.DEFAULT_IMAGE
                          }
                          alt={value.product_name}
                          width="280"
                          height="315"
                        />
                      </a>
                    </figure>
                    <div className="product-details">
                      <h3 className="product-name">
                        <a href={"/product/" + value.product_slug}>
                          {value.product_name}
                        </a>
                      </h3>
                      <div className="product-price">
                        <ins className="new-price">
                          ₹{numeral(value.product_selling_price).format("0,0.00")}
                        </ins>
                      </div>
                      {value.product_type === 0 ? (
                        setSession ?
                          <button
                            className="btn btn-primary-line"
                            onClick={(e) => addtocartsession(value)}
                          >
                            Add to Cart
                          </button>
                          :
                          <button
                            className="btn btn-primary-line"
                            onClick={(e) => addtocart(value)}
                          >
                            Add to Cart
                          </button>

                      ) : (
                        <button
                          className="btn btn-primary-line"
                          onClick={(e) => variationModal(value)}
                        >
                          Add to Cart
                        </button>
                      )
                      }
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </BrowserView>
      )}

      <div className="cartbox">
        <div className="cartbox-header">
          <h4 className="cartbox-title">Shopping Cart</h4>
          <a
            href={"javscript:void(0)"}
            className="cartbox-close"
            onClick={(e) => cartModal()}
          >
            <i className="d-icon-times"></i>
          </a>
        </div>
        {contextValues.cartSessionData.length > 0 ? (
          <>
            <div className="cartbox-scroll">
              {contextValues.cartSessionData.map((value, index) => {
                let mrpValue = parseFloat(value.product_price);
                let sellingPriceValue = parseFloat(value.product_selling_price);
                let discount = 0;
                if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
                  discount = (((mrpValue - sellingPriceValue) / mrpValue) * 100);
                } else {
                  discount = 0;
                }
                itemTotal = contextValues.cartSessionData.reduce(
                  (total, value) =>
                    total + parseFloat(value.product_selling_price) * Number(value.quantity),
                  0
                );
                return (
                  <div className="product-cart" key={index}>
                    <a href={"javascript:void(0)"} className="product-remove" onClick={(e) => removeProduct(value)}>
                      <i className="fa fa-trash-alt"></i>
                    </a>
                    <figure className="product-media">
                      <a href={"/product/" + value.product_slug}>
                        <img src={value.product_image} />
                      </a>
                    </figure>
                    <div className="product-detail">
                      <a href={"/product/" + value.product_slug} className="product-name">
                        {value.product_name}
                      </a>
                      <div className="price-box">
                        <span className="product-quantity">
                          {value.quantity}
                        </span>
                        <span className="product-price">
                          <ins className="new-price">{multiCurrency(value.product_selling_price)}</ins>
                          {discount > 0 ? (
                            <>
                              <del className="old-price">
                                {multiCurrency(value.product_price)}
                              </del>
                              <span className="off">{Math.round(discount)}% Off</span>
                            </>
                          ) : null} 
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
              <MobileView>
              {recommendedData.length > 0 ?
                <div className="mcartbox-recom mt-4">
                  <h6 className="tx-14 mb-3">Recommendations For You</h6>
                  <Swiper {...productCarouselOptions}>
                    {recommendedData.map((value, indexProduct) => {
                      return (
                        <SwiperSlide key={indexProduct}>
                          <div className="product" key={indexProduct}>
                            <figure className="product-media">
                              <a href={"/product/" + value.product_slug}>
                                <img
                                  src={
                                    value.product_image != null
                                      ? value.product_image
                                      : constant.DEFAULT_IMAGE
                                  }
                                  alt={value.product_name}
                                  width="280"
                                  height="315"
                                />
                              </a>
                            </figure>
                            <div className="product-details">
                              <h3 className="product-name">
                                <a href={"/product/" + value.product_slug}>
                                  {value.product_name}
                                </a>
                              </h3>
                              <div className="product-price">
                                <ins className="new-price">
                                  {multiCurrency(value.product_selling_price)}

                                </ins>
                              </div>
                              {value.product_type === 0 ? (
                                setSession ?
                                  <button
                                    className="btn btn-primary-line btn-small"
                                    onClick={(e) => addtocartsession(value)}
                                  >
                                    Add to Cart
                                  </button>
                                  : <button
                                    className="btn btn-primary-line btn-small"
                                    onClick={(e) => addtocart(value)}
                                  >
                                    Add to Cart
                                  </button>
                              ) : (
                                <button
                                  className="btn btn-primary-line btn-small"
                                  onClick={(e) => variationModal(value)}
                                >
                                  Add to Cart
                                </button>
                              )}
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
                : null}

            </MobileView>
            </div>
            
            <div className="cartbox-footer">
              <div className="cartbox-total">
                <span>Subtotal:</span>
                <span>{multiCurrency(itemTotal)}</span>
              </div>
              <div className="cartbox-action">
                <div className="d-flex mb-4 justify-content-between">
                  <div><p className="mb-0 tx-12">Discount code to be applied at checkout.</p></div>
                </div>
                <a href="javascript:void(0)" className="btn btn-primary" onClick={(e) => checkoutModal()}>Go to Checkout</a>
              </div>
            </div>
          </>
        ) : (
          <div className="noimg">
            <img src="/img/empty-cart.webp" className="img-fluid mb-3" />
            <h6>Your cart is empty!</h6>
            <p>There is nothing in your cart. Let's add some items</p>
            <a href="/" className="btn btn-primary-outline btn-block btn-small">
              Continue Shopping
            </a>
          </div>
        )}
      </div>
      {contextValues.toggleVariationModal && (<VariationModal />)}
    </>
  );
}
export default CartModal;
