import './App.css';
import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Home from './Containers/Home';
import Account from './Containers/Account/account';
import AccountOverview from './Containers/Account/account_overview';
import Address from './Containers/Account/address';
import Profile from './Containers/Account/profile';
import Wishlist from './Containers/Account/wishlist';
import Orders from './Containers/Account/orders';
import Productlist from './Containers/Productlist';
import Productdetails from './Containers/Productdetails';
import Pages from './Containers/Pages';
import Cart from './Containers/Cart';
import Search from './Containers/Search';
import HelpandSupport from './Containers/Account/help_support';
import Aboutus from './Containers/Account/about_us';
import OrderDetails from './Containers/Account/order_details';
import Error404 from './Containers/Error/error_404';
import Contact from './Containers/Contact';
import Blog from './Containers/Blog';
import BlogsDetails from './Containers/BlogsDetails';
import Category from './Containers/Category';
import CategoryProduct from './Containers/Categoryproduct';
import ChangePassword from './Containers/Account/change_password';
import CartCheckout from './Containers/Cart/checkout';
import CartAddress from './Containers/Cart/address';
import Thankyou from './Containers/Cart/thankyou';
import ResetPasswordLink from './Containers/ResetPasswordLink';
import TrackOrder from './Containers/TrackOrder';
import Collection from './Containers/Collection';
import Feedback from './Containers/FeedBack';
import Career from './Containers/Career';
import CancelPayment from './Containers/Cart/cancel_payment';
import CheckOut from "../src/Containers/Checkout/index"
import CheckoutModal from './Components/Modals/checkout_modal';
import SearchResult from './Containers/Searchresult';



function App() {
  return (
    <div className="App">
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme="light"
        />
       <Router>
        <Routes>
          <Route exact path='/' activeClassName="active" element={<Home />} />
 
            {/* Account Routes Section */}
            <Route path="/account" activeClassName="active" element={<Account />} />
            <Route path="/account/account-overview" activeClassName="active" element={<AccountOverview />} />
            <Route path="/account/address" activeClassName="active" element={<Address />} />
            <Route path="/account/profile" activeClassName="active" element={<Profile />} />
            <Route path="/account/wishlist" activeClassName="active" element={<Wishlist />} />
            <Route path="/account/orders" activeClassName="active" element={<Orders />} />
            <Route path="/account/help-and-support" activeClassName="active" element={<HelpandSupport />} />
            <Route path="/account/about-us" activeClassName="active" element={<Aboutus />} />
            <Route path="/account/change-password" activeClassName="active" element={<ChangePassword />} />
            <Route path="/account/order-details/:id" activeClassName="active" element={<OrderDetails />} />
          {/* End */}
          
          <Route path="/search" activeClassName="active" element={<Search />} />
          <Route path="/search-result/:slug" activeClassName="active" element={<SearchResult />} />
          <Route path="/feedback-form" activeClassName="active" element={<Feedback />} />
          <Route path="/career" activeClassName="active" element={<Career />} />
          {/* <Route path="/cart" activeClassName="active" element={<Cart />} />
          <Route path="/address" activeClassName="active" element={<CartAddress />} />
          <Route path="/checkout" activeClassName="active" element={<CheckoutModal />} /> */}
          <Route path="/thankyou/:id" activeClassName="active" element={<Thankyou />} />
          <Route path="/cancel-payment" activeClassName="active" element={<CancelPayment />} />
          {/* <Route path="/error_404" activeClassName="active" element={<Error404 />} /> */}
          <Route path="/contact-us" activeClassName="active" element={<Contact/>} />
          <Route path="/blogs" activeClassName="active" element={<Blog />} />
          <Route exact path='/blogs/:slug' activeClassName="active" element={ <BlogsDetails /> }/>
          <Route path="/category" activeClassName="active" element={<Category />} />
          <Route path="/track-order" activeClassName="active" element={<TrackOrder />} />


          <Route exact path='/collection' activeClassName="active" element={<Collection />} />
          <Route exact path='/collection/:slug' activeClassName="active" element={<Collection />} />
          <Route exact path='/collection/:type/:slug?' activeClassName="active" element={<Collection />} />

          <Route exact path='/product/:slug' activeClassName="active" element={<Productdetails />} />
          <Route exact path='/category/:slug' activeClassName="active" element={<CategoryProduct />} />
          <Route exact path='/shop' activeClassName="active" element={<Productlist />} />
          <Route exact path='/resetpasswordlink' activeClassName="active" element={<ResetPasswordLink />} />
          <Route exact path='/:slug' activeClassName="active" element={<Pages />} /> 


          <Route path="/*" element={<Navigate to="/error_404" replace />} />
            <Route path="/error_404" element={<Error404 />} />

   



        </Routes>
      </Router>
    </div>
  );
}

export default App;
